import React from "react"
import { Link } from "gatsby"

import TopNav from '../components/Nav/topNav'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <TopNav />
    <SEO title="404: Not found" />
    
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-dark">
      <h1 className="mb-3 pb-2" style={{
        borderBottom: '0.1rem solid white',
      }} data-sal="zoom-in" data-sal-duration="800">
        NOT FOUND
      </h1>
      <p data-sal="slide-up" data-sal-duration="800" data-sal-delay="200">
        You just hit a route that doesn&#39;t exist...
      </p>
      <p data-sal="slide-up" data-sal-duration="800" data-sal-delay="400">
        Click <Link className="text-secondary" to="/">here</Link> to head back.
      </p>
    </div>
  </>
)

export default NotFoundPage
